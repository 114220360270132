import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar, IconButton, Link, Typography, Badge, Avatar, Button } from '@mui/material';
import Iconify from '../../components/Iconify';
import Searchbar from './Searchbar';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'white',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const [activeLink, setActiveLink] = useState(null);

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>

        <Searchbar />

        {/* Center the navigation links */}
        <Box sx={{ flexGrow: 1 }} display="flex" justifyContent="center">
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4, alignItems: 'center' }}>
            {['About', 'Tools', 'Help'].map((item) => (
              <Box key={item} sx={{ position: 'relative' }}>
                <Link
                  href="#"
                  underline="none"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    color: 'black',
                    paddingBottom: '5px',
                    borderBottom: activeLink === item ? '2px solid blue' : 'none',
                  }}
                  onClick={() => handleLinkClick(item)}
                >
                  <Typography variant="subtitle1">{item}</Typography>
                </Link>
              </Box>
            ))}
          </Box>
        </Box>

        {/* Notification icons */}
        <Box display="flex" alignItems="center" sx={{ gap: 10 }}>
          <Box position="relative">
            <Badge
              badgeContent={12}
              color="secondary"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
              }}
            >
              <Box
                bgcolor="#E9ECFF"
                height={45}
                width={45}
                style={{
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Iconify icon="typcn:bell" style={{ fontSize: 30, color: '#2F4CDD' }} />
              </Box>
            </Badge>
          </Box>

          {/* Second notification icon */}
          <Box position="relative">
            <Badge
              badgeContent={5}
              color="secondary"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
              }}
            >
              <Box
                bgcolor="#E9ECFF"
                height={45}
                width={45}
                style={{
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Iconify icon="mdi-light:message-text" style={{ fontSize: 30, color: '#2F4CDD' }} />
              </Box>
            </Badge>
          </Box>

          {/* Settings icon */}
          <Box position="relative">
            <Badge
              badgeContent={5}
              color="secondary"
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                transform: 'translate(50%, -50%)',
              }}
            >
              <Box
                bgcolor="#FFEBE7"
                height={45}
                width={45}
                style={{
                  borderRadius: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Iconify icon="iconamoon:settings-thin" style={{ fontSize: 30, color: '#FF6D4D' }} />
              </Box>
            </Badge>
          </Box>

          {/* User Greeting and Avatar */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#2F4CDD',
              color: 'white',
              borderRadius: '12px 32px 32px 12px',
              paddingRight: '50px',
              paddingLeft: 2,
              textTransform: 'none',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              position: 'relative',
              overflow: 'hidden',
              '&:hover': {
                backgroundColor: '#3C4469',
              },
            }}
            endIcon={
              <Avatar
                sx={{
                  width: 36,
                  height: 36,
                  position: 'absolute',
                  right: -2,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  borderRadius: '50%',
                  backgroundColor: 'white',
                  boxShadow: '0 0 0 2px #2F4CDD',
                }}
              />
            }
          >
            Hello, Sairam
          </Button>
        </Box>
      </ToolbarStyle>
    </RootStyle>
  );
}
