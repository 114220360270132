import React from 'react';
import { Box, Grid, Card, CardContent, Typography } from '@mui/material';
import { FilterAlt, ArrowDropDown } from '@mui/icons-material';
import cardImg1 from "../assest/activity_1.png"
import cardImg2 from "../assest/ic_customer.png"

function DashboardApp() {
  return (
    <Box sx={{ padding: 1, position: 'relative' }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Welcome to Admin!
      </Typography>

      {/* Filter Icon */}
      <Box    sx={{
          position: 'absolute',
          top: 16,
          right: 300,
          width: "150px",
          height: "68px",
          gap: "0px",
          opacity: "0px",
        }}>
          <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>   <FilterAlt sx={{color:"blue"}} />
        <Typography variant="body1">Filter</Typography>
        <ArrowDropDown /></Box>
     
      </Box>

 {/* Filter Icon */}
 <Box    sx={{
          position: 'absolute',
          top: 16,
          right: 25,
          width: "200px",
          height: "68px",
          gap: "0px",
          opacity: "0px",
        }}>
          <Box sx={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>   <FilterAlt  sx={{color:"blue"}}/>
        <Box sx={{display:"flex", alignItems:"left", justifyContent:"center", flexDirection:"column"}}>
        <Typography sx={{fontSize:14}}>Filter Period</Typography>
        <Typography sx={{fontSize:8}}> 4 June 2024 - 4 July 2024</Typography>

        </Box>
        
       
        <ArrowDropDown /></Box>
     
      </Box>
      <Grid container spacing={3}>
        {/* Top Cards */}
        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ borderRadius: "50%", padding: "10px", backgroundColor: "#DEE4FF", }}>
              <img src={cardImg1} alt="Card" />
            </Box>
            <CardContent>
              <Typography variant="h3">12</Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>Total User</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ borderRadius: "50%", padding: "10px", backgroundColor: "#DEE4FF", }}>
              <img src={cardImg1} alt="Card" />
            </Box>
            <CardContent>
              <Typography variant="h3">6</Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>Completed order</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ borderRadius: "50%", padding: "10px", backgroundColor: "#DEE4FF", }}>
              <img src={cardImg2} alt="Card" />
            </Box>
            <CardContent>
              <Typography variant="h3">67</Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>Pending Order</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card sx={{ display: "flex", padding: "10px", justifyContent: "center", alignItems: "center" }}>
            <Box sx={{ borderRadius: "50%", padding: "10px", backgroundColor: "#DEE4FF", }}>
              <img src={cardImg1} alt="Card" />
            </Box>
            <CardContent>
              <Typography variant="h3">7</Typography>
              <Typography variant="subtitle1" sx={{ fontSize: "10px" }}>Cancel order</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DashboardApp;
