import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Pagination,
 
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Paper,
  Button,
  Dialog,
 
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
 
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
const Services = () => {
  const [rows, setRows] = useState([
    { id: 1, image: '/th (3).jpeg', name: 'Service 1', price: '$100', about: 'Description of service 1' },
    { id: 2, image: '/th (3).jpeg', name: 'Service 2', price: '$200', about: 'Description of service 2' },
    { id: 3, image: '/th (3).jpeg', name: 'Service 3', price: '$300', about: 'Description of service 3' },
    // Add more sample data here
  ]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState();
  const [formData, setFormData] = useState({
    serviceName: '',
    description: '',
    amount: '',
    discount: '',
    fastingTime: '',
    resultDuration: '',
    sampleType: '',
    ageGroup: '',
    homeSampleCollection: false,
    image: null,
  });

  // Handle sorting
  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter rows based on search term
  const filteredRows = rows.filter(row =>
    row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.price.toLowerCase().includes(searchTerm.toLowerCase()) ||
    row.about.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Sort rows
  const sortedRows = filteredRows.sort((a, b) => {
    if (orderBy === 'id') {
      return order === 'asc' ? a.id - b.id : b.id - a.id;
    }
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  // Paginate rows
  const paginatedRows = sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // Calculate total pages
  const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

  // Open dialog
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  // Close dialog
  const handleClose = () => {
    setOpenDialog(false);
  };

  // Handle form field changes
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  // Handle image upload
  const handleImageChange = (event) => {
    setFormData({
      ...formData,
      image: event.target.files[0]
    });
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    // Process form data
    console.log('Form Data:', formData);
    handleClose();
  };

  return (
    <>
    
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
      <Typography variant="h4" gutterBottom p={1}>
        Services Management
      </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{ backgroundColor: 'blue', color: 'white', '&:hover': { backgroundColor: 'darkblue' }, width:200, height:60, fontSize:16 }}
            onClick={handleClickOpen}
          >
            Add Service
          </Button>
        </Box>
      <Box p={3}>
        <TableContainer component={Paper}>
          <Box display="flex" flexDirection="column" p={2}>
            <Box display="flex" justifyContent="space-between" mb={2} flexWrap="wrap">
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, mb: 2 }}>
                <InputLabel>Entries</InputLabel>
                <Select
                  value={rowsPerPage}
                  onChange={handleChangeRowsPerPage}
                  label="Entries"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Search"
                variant="outlined"
                size="small"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                sx={{ width: { xs: '100%', sm: '300px' } }}
              />
            </Box>
            <Table
              sx={{
                border: '1px solid grey', // Example border color
                fontSize: '1rem',
                minWidth: 650,
                borderBottom: '2px solid black'
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    Sr No.
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={orderBy === 'id' ? order : 'asc'}
                      onClick={() => handleRequestSort('id')}
                    >
                      {orderBy === 'id' ? (order === 'asc' ? ' ▲' : ' ▼') : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'image'}
                      direction={orderBy === 'image' ? order : 'asc'}
                      onClick={() => handleRequestSort('image')}
                    >
                      Image
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={() => handleRequestSort('name')}
                    >
                      Service Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'price'}
                      direction={orderBy === 'price' ? order : 'asc'}
                      onClick={() => handleRequestSort('price')}
                    >
                      Price
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'about'}
                      direction={orderBy === 'about' ? order : 'asc'}
                      onClick={() => handleRequestSort('about')}
                    >
                      About
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>
                      <img src={row.image} alt={row.name} style={{ width: 50 }} />
                    </TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.price}</TableCell>
                    <TableCell>{row.about}</TableCell>
                    <TableCell>
                      <Box>
                        <EditIcon  style={{background:"blue", color:"white", padding:10, borderRadius:25, fontSize:50}}/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Box mt={2} display="flex" justifyContent="flex-end" flexWrap="wrap">
              <Button
                variant="outlined"
                disabled={page === 0}
                onClick={() => handleChangePage(null, page - 1)}
                sx={{ marginRight: 1 }}
              >
                Previous
              </Button>
              
              <Pagination
                count={totalPages}
                page={page + 1}
                onChange={handleChangePage}
                color="primary"
                shape="rounded"
                sx={{ 
                  '.MuiPaginationItem-root': {
                    margin: '0 2px',
                  },
                  '.MuiPaginationItem-ellipsis': {
                    display: 'none',
                  },
                  '.MuiPaginationItem-page.Mui-selected': {
                    backgroundColor: 'blue',
                    color: 'white',
                  },
                  '.MuiPaginationItem-page.Mui-disabled': {
                    opacity: 0.5,
                  }
                }}
              />

              <Button
                variant="outlined"
                disabled={page >= totalPages - 1}
                onClick={() => handleChangePage(null, page + 1)}
                sx={{ marginLeft: 1 }}
              >
                Next
              </Button>
            </Box>
          </Box>
        </TableContainer>
      </Box>
      <Dialog
      open={openDialog}
      onClose={handleClose}
      fullWidth
      maxWidth="md" // You can adjust the size ('xs', 'sm', 'md', 'lg', 'xl') according to your needs
    >
      <DialogTitle>Add New Service</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Box display="flex" flexDirection="column" gap={2}>
            <Box>
              <Typography variant="subtitle1">Service Name</Typography>
              <MuiTextField
                name="serviceName"
                value={formData.serviceName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Description</Typography>
              <MuiTextField
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                required
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Amount</Typography>
              <MuiTextField
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                fullWidth
                required
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Discount</Typography>
              <MuiTextField
                name="discount"
                value={formData.discount}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Fasting Time</Typography>
              <MuiTextField
                name="fastingTime"
                value={formData.fastingTime}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Result Duration</Typography>
              <MuiTextField
                name="resultDuration"
                value={formData.resultDuration}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Sample Type</Typography>
              <MuiTextField
                name="sampleType"
                value={formData.sampleType}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Age Group</Typography>
              <MuiTextField
                name="ageGroup"
                value={formData.ageGroup}
                onChange={handleChange}
                fullWidth
              />
            </Box>
            <Box>
              <Typography variant="subtitle1">Home Sample Collection</Typography>
              <MuiTextField
                name="homeSampleCollection"
                value={formData.homeSampleCollection}
                onChange={handleChange}
                fullWidth
              />
            </Box>
         
            <Box
      color="primary"
      component="label"
      sx={{
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        width: 40, // Adjust width of the icon button
        height: 40, // Adjust height of the icon button
        paddingLeft: 5,// Remove default padding
        marginBottom:5
      }}
    >
      <PhotoCameraIcon /> Upload Image
      <input
        type="file"
        hidden
        onChange={handleImageChange}
      />
    </Box>

            {formData.image && (
              <img
                src={URL.createObjectURL(formData.image)}
                alt="Selected"
                style={{ width: 100, height: 100, objectFit: 'cover'}}
              />
            )}
          </Box>
          
            <Button type="submit" variant="contained" color="primary" fullWidth>Submit</Button>
         
        </form>
      </DialogContent>
    </Dialog>

    </>
  );
};

export default Services;
