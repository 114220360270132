import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
//
import DashboardApp from './pages/DashboardApp';
import Services from './pages/Services';
import Banner from './pages/Banner';
import Packages from './pages/Packages';
import Order from './pages/Order';
import CollectionBoy from './pages/CollectionBoy';
import PushNotifications from './pages/PushNotifications';


export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="app" /> },
        { path: 'app', element: <DashboardApp /> },
       

        { path: 'Services', element: <Services/> },
        { path: 'Banner', element: <Banner/> },
        { path: 'Packages', element: <Packages/> },
        { path: 'Order', element: <Order /> },
        { path: 'Collection-Boy', element: <CollectionBoy /> },
        { path: 'Push-Notifications', element: <PushNotifications /> },
        
      ],
    },
   
  
  ]);
}
