// component
import Iconify from '../../components/Iconify';

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

export const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('pajamas:tachometer'),  // Dashboard icon
  },
  {
    title: 'Services',
    path: '/dashboard/Services',
    icon: getIcon('carbon:service-desk'),  // Service icon
  },
  {
    title: 'Banner',
    path: '/dashboard/Banner',
    icon: getIcon('mdi:flag-variant-outline'),  // Banner flag icon
  },
  {
    title: 'Packages',
    path: '/dashboard/Packages',
    icon: getIcon('ion:cube-outline'),  // Package box icon
  },
  {
    title: 'Order',
    path: '/dashboard/Order',
    icon: getIcon('mdi:clipboard-list-outline'),  // Order list icon
  },
  {
    title: 'Collection Boy',
    path: '/dashboard/Collection-Boy',
    icon: getIcon('mdi:run-fast'),  // Runner/Delivery icon
  },
  {
    title: 'Push Notifications',
    path: '/dashboard/Push-Notifications',
    icon: getIcon('mdi:bell-outline'),  // Notification bell icon
  },
];
